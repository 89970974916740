import React, { useEffect,useState } from 'react';
import {Image as RnImage, StyleSheet, Text, View,ScrollView,Dimensions,Pressable ,Platform} from 'react-native';
import {Image} from 'expo-image'
import axios from 'axios';
import Aes from './Aes'

export default function App1({func,openH5}) {

  const [TopSource, setTopSource] = useState('')
  const [BottomSource, setBottomSource] = useState('')
  const [LogoSource, setLogoSource] = useState('')

  const DownLoadImage_top= async() => {
    try {
      const url = require('./assets/1/top.gif.ceb');
      const response = await axios.get(url,{responseType:'arraybuffer'})
      //console.log(response)
      const data = Aes.DecryptIMG1(response.data)
      setTopSource('data:image/jpeg;base64,'+Aes.toBase64(data))
    } catch (error) {
      setIsError(true)
    }
  }
  const DownLoadImage_bottom= async() => {
    try {
      const url = require('./assets/1/bottom.jpg.ceb');
      const response = await axios.get(url,{responseType:'arraybuffer'})
      //console.log(response)
      const data = Aes.DecryptIMG1(response.data)
      setBottomSource('data:image/jpeg;base64,'+Aes.toBase64(data))
    } catch (error) {
      setIsError(true)
    }
  }
  const DownLoadImage_logo= async() => {
    try {
      const url = require('./assets/1/Logo.png.ceb');
      const response = await axios.get(url,{responseType:'arraybuffer'})
      //console.log(response)
      const data = Aes.DecryptIMG1(response.data)
      setLogoSource('data:image/jpeg;base64,'+Aes.toBase64(data))
    } catch (error) {
      setIsError(true)
    }
  }

  useEffect(() => {
    //GetDownLoad();
    DownLoadImage_top()
    DownLoadImage_bottom()
    DownLoadImage_logo()
  }, []);

  return (
    <View style={{flex:1,backgroundColor:'#000'}}>
      <Pressable onPress={()=>func()} style={styles.container}>
        <ScrollView>
          <Image
            recyclingKey={"top"}
              style={{
                width: '100%',
                height: 294,
                backgroundColor: '#eee',
              }}
              source={TopSource}
              contentFit='fill'
          />
          <Image
            recyclingKey={"bottom"}
              style={{
                width: '100%',
                height: 600,
                backgroundColor: '#eee',
              }}
              source={BottomSource}
              contentFit='fill'
          />
        </ScrollView>

        <View style={{position:'fixed',justifyContent:'center',bottom:0,left:0,height:60,right:0,backgroundColor:'rgba(0, 0, 0, 0.8)'}}>
          <View style={{position:'absolute', left:12}}>
            <View style={{flexDirection:'row'}}>
              <Image
                recyclingKey={"htmlLogo"}
                style={{
                  width: 40,
                  height: 40,
                  backgroundColor: '#eee',
                  borderRadius:8
                }}
                source={LogoSource}
                contentFit='fill'
              />
              <View style={{marginLeft:10}}>
                  <Text style={{ color:'#fff',fontSize:14,fontWeight:'bold'}}>夜殿APP</Text>
                  <Text style={{ color:'#fff',fontSize:14,fontWeight:'bold'}}>海量免费高清独家视频</Text>
              </View>
            </View>
          </View>

          <View style={{position:'absolute', right:12,justifyContent:'center',alignItems:'center',flexDirection:'row'}}>
                
            <Pressable onPress={()=>openH5()} style={{backgroundColor:'#ff7500',borderRadius:40}}> 
              <Text style={{margin:10,marginHorizontal:12, color:'#fff',fontSize:14,fontWeight:'bold'}}>网页版</Text>
            </Pressable>
                
            <View style={{marginLeft:10, backgroundColor:'#ff7500',borderRadius:40}}> 
              <Text style={{margin:10,marginHorizontal:12, color:'#fff',fontSize:14,fontWeight:'bold'}}>点击下载</Text>
            </View>
          </View>

        </View>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

