import CryptoJS from './CryptoJS';
import { Buffer } from 'buffer'

const AES = {
    Encrypt: (word:any) => { //加密方法
        //console.log(22222)
        var key = CryptoJS.enc.Utf8.parse('12345678900000001234567890000000'); 
        var iv = CryptoJS.enc.Utf8.parse('1234567890000000');
        let srcs = CryptoJS.enc.Utf8.parse(word);
        let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        return encrypted.ciphertext.toString().toUpperCase();
    },
    Decrypt: (word:any) => { //解密方法
        var key = CryptoJS.enc.Utf8.parse('12345678900000001234567890000000'); 
        var iv = CryptoJS.enc.Utf8.parse('1234567890000000');
        let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
        let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
        let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedStr.toString();
    },
    toBase64:(input) =>{
        return Buffer.from(input, 'utf-8').toString('base64')
    },
    DecryptIMG1:(array:any)=>{
        const bytes = new Uint8Array(array);
        for (let index = 0; index < 100; index++) {
          bytes[index] = bytes[index] ^ 968542557
        }
        // for (let index = 0; index < bytes.byteLength; index++) {
        //   aaa += String.fromCharCode(bytes[index])
        // }
        return bytes
    },
    DecryptIMG2:(array:any)=>{
        const bytes = new Uint8Array(array);
        for (let index = 0; index < 100; index++) {
          bytes[index] = bytes[index] ^ 857431446
        }
        return bytes
    }
}
export default AES;